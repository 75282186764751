import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { FormProvider } from "./context/form.context";
import { CatalogProvider } from "./context/services.context";
import App from "./App";
import "./index.scss";
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <GoogleReCaptchaProvider
    reCaptchaKey={process.env.REACT_APP_RECAPTCHA_V3_PUBLIC}
    language="ru"
    scriptProps={{
      async: false, // optional, default to false,
      defer: false, // optional, default to false
      appendTo: 'head', // optional, default to "head", can be "head" or "body",
      nonce: undefined // optional, default undefined
    }}
  >
  <BrowserRouter>
    <CatalogProvider>
      <FormProvider>
        <App />
      </FormProvider>
    </CatalogProvider>
  </BrowserRouter>
  </GoogleReCaptchaProvider>
);
