import React, { lazy, Suspense, useContext, useEffect, useState } from "react";
import { Route, Routes, useNavigate } from "react-router";
import { ServicesContext } from "./context/services.context";
import { fetchServices, getSubdomain, Loading } from "@supermegapay/common";
import NavBar from "./components/NavBar/NavBar";
import HomePage from "./pages/Home/HomePage";
import NotFound from "./pages/NotFound/NotFound";
import Footer from "./components/Footer/Footer";
import "./App.scss";

const TransactionPageLazy = lazy(() =>
  import("./pages/Transaction/TransactionPage")
);
const TransactionResultLazy = lazy(() =>
  import("./pages/TransactionResult/TransactionResult")
);
const ServicesPageLazy = lazy(() =>
  import("./pages/Services/ServicesPage")
);

function App() {
  const { services, setServices } = useContext(ServicesContext);
  const navigate = useNavigate();
  const hostName = window.location.hostname
  const domain = process.env.REACT_APP_BASE_URL

  useEffect(() => {
    !services && fetchServices(setServices);
  }, [services, setServices]);

  useEffect(() => {
    if (domain !== hostName) {
      const subDomain = getSubdomain(hostName)
      const item = services?.catalog.find(item => item.subdomain === subDomain);
      item && navigate(`/pay/${item.id}`)
    }
  }, [hostName, services])

  return (
    <div className="App">
      <NavBar />
      <main className="main-content">
        <Suspense fallback={<Loading />}>
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/pay/:id" element={<TransactionPageLazy />} />
            <Route path="/result" element={<TransactionResultLazy />} />
            <Route path="/services/:id" element={<ServicesPageLazy />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </Suspense>
      </main>
      <Footer />
    </div>
  );
}

export default App;
